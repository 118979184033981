import * as React from 'react';

import type { ISanityContactForm } from '../../fragments/sanity-contact-form-parts';
import { ContactForm } from '../contact-form';
import { ContactInfo } from '../contact-info';
import { FullWidthContactForm } from '../full-width-contact-form';
import { GoogleMap, MapIFrame } from './google-map';

interface ContactSectionProps {
  contactSection: ISanityContactForm;
}

function ContactSection({ contactSection }: ContactSectionProps): JSX.Element {
  if (contactSection.disableMap) {
    return <FullWidthContactForm />;
  }
  return <ContactWithMap contactSection={contactSection} />;
}

function ContactWithMap({ contactSection }: ContactSectionProps): JSX.Element {
  return (
    <div className="relative lg:px-12 bg-gradient-to-tr from-primary to-tertiary ">
      <div className="relative z-20 py-16 lg:bg-gradient-to-tr from-primary to-tertiary lg:w-3/5 lg:clip-polygon-b-r">
        <div className="px-8 mx-auto contact-form-width lg:mx-0 lg:px-0">
          <ContactForm />
        </div>
      </div>
      <div className="top-0 right-0 z-10 flex lg:aspect-none min-h-[500px] aspect-w-4 aspect-h-3 lg:absolute lg:w-8/12 lg:h-full">
        <div className="absolute inset-0 flex-1">
          <MapIFrame />
        </div>

        {contactSection?.showContactInfo === true && (
          <div className="absolute z-10 flex items-center justify-end">
            <div className="relative w-[450px] clip-polygon-b-l">
              <div className="px-4 py-10 pr-8 lg:py-12 bg-primary ">
                <div className="w-8/12 ml-auto ">
                  <ContactInfo />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export { ContactSection };
