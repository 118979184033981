import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import * as React from 'react';
import { HiMenu } from 'react-icons/hi';

import type {
  INavDropdown,
  INavLink,
  INavPage,
} from '../hooks/use-sanity-menu';
import { useSanityMenu } from '../hooks/use-sanity-menu';
import { useSanitySEOSettings } from '../hooks/use-sanity-seo-settings';
import { LogoLandscape } from '../icons/logo-landscape';
import { classNames } from '../utils/classnames';
import { MobileMenu } from './mobile-menu';

function Nav(): JSX.Element {
  const { items } = useSanityMenu();
  const { siteTitle } = useSanitySEOSettings();
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <header className="fixed inset-x-0 top-0 z-30 text-light max-w-[1920px] w-full mx-auto ">
      <div className="relative">
        <div className="px-4 py-5 sm:px-6 sm:py-4 lg:px-8">
          <div className="relative flex items-start justify-between mx-auto space-x-6 lg:inline-flex max-w-screen-2xl lg:justify-start">
            <div className="relative">
              <span
                aria-hidden
                className="absolute inset-0 block -ml-12 transform shadow -skew-x-25 bg-light"
              />
              <Link
                to="/"
                className="relative flex py-4 pl-2 pr-6 rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
              >
                <span className="sr-only">{siteTitle}</span>
                <LogoLandscape
                  aria-hidden
                  className="w-auto h-8 sm:h-10 text-primary"
                />
              </Link>
            </div>
            <div className="relative lg:hidden">
              <button
                type="button"
                onClick={() => setIsOpen((prev) => !prev)}
                className="inline-flex items-center justify-center px-6 py-2 transition duration-150 ease-in-out transform -skew-x-25 bg-primary text-light hover:bg-opacity-25 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
              >
                <span
                  aria-hidden
                  className="absolute inset-0 block -mr-12 bg-primary"
                />
                <span className="sr-only">Open menu</span>
                <HiMenu
                  aria-hidden
                  className="relative w-6 h-6 transform skew-x-25"
                />
              </button>
            </div>
            <div className="relative hidden lg:flex lg:items-center lg:justify-between">
              <nav className="flex transform shadow whitespace-nowrap -skew-x-25">
                {items.map((item) => {
                  switch (item._type) {
                    case 'navLink':
                      return <NavLink key={item.id} item={item} />;

                    case 'navPage':
                      return <NavPage key={item.id} item={item} />;

                    case 'navDropdown':
                      return <NavDropdown key={item.id} item={item} />;

                    default:
                      return null;
                  }
                })}
              </nav>
            </div>
          </div>
        </div>
      </div>
      <MobileMenu isOpen={isOpen} setIsOpen={setIsOpen} />
    </header>
  );
}

interface NavLinkProps {
  item: INavLink;
}

function NavLink({ item }: NavLinkProps): JSX.Element {
  return (
    <div>
      <a
        key={item.id}
        href={item.url}
        className="inline-flex overflow-hidden text-base font-medium uppercase transition duration-150 ease-in-out hover:bg-secondary bg-primary focus:outline-none focus:ring-2 focus:ring-offset-dark focus:ring-offset-2 focus:ring-primary"
      >
        <span className="px-8 py-4 transform-gpu skew-x-25">{item.title}</span>
      </a>
    </div>
  );
}

interface NavPageProps {
  item: INavPage;
}

function NavPage({ item }: NavPageProps): JSX.Element {
  const { pathname } = useLocation();
  const href = item.page.slug ? `/${item.page.slug.current}/` : '/';
  return (
    <div>
      <Link
        key={item.id}
        to={href}
        className={classNames(
          pathname === href ? 'bg-secondary' : 'bg-primary',
          'inline-flex overflow-hidden text-base font-medium uppercase transition duration-150 ease-in-out hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-dark focus:ring-offset-2 focus:ring-primary'
        )}
      >
        <span className="px-8 py-4 transform-gpu skew-x-25">{item.title}</span>
      </Link>
    </div>
  );
}

interface NavDropdownProps {
  item: INavDropdown;
}

function NavDropdown({ item }: NavDropdownProps): JSX.Element {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={classNames(
              open ? 'text-light' : 'text-gray-200',
              'inline-flex hover:bg-secondary transition duration-150 ease-in-out  items-center px-8 py-4 text-base font-medium uppercase   bg-primary group hover:text-light focus:outline-none focus:ring-2 focus:ring-offset-dark focus:ring-offset-2 focus:ring-primary'
            )}
          >
            <span className="transform-gpu skew-x-25">{item.title}</span>
            <ChevronDownIcon
              className={classNames(
                open ? 'text-gray-100' : 'text-gray-300',
                'ml-2 h-5 w-5 group-hover:text-gray-50 transform-gpu skew-x-25'
              )}
              aria-hidden
            />
          </Popover.Button>

          <Transition
            show={open}
            as={React.Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              static
              className="absolute z-10 w-screen max-w-xs px-2 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0"
            >
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-dark ring-opacity-5">
                <div className="relative grid gap-6 p-8 bg-light sm:gap-8">
                  {item.dropdownItems.map((dropdownItem) => {
                    if (dropdownItem._type === 'navLink') {
                      return (
                        <NavDropdownLink key={item.id} item={dropdownItem} />
                      );
                    }
                    if (dropdownItem._type === 'navPage') {
                      return (
                        <NavDropdownPage key={item.id} item={dropdownItem} />
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

function NavDropdownLink({ item }: NavLinkProps): JSX.Element {
  return (
    <a
      key={item.id}
      href={item.url}
      className="block p-3 -m-3 transition duration-150 ease-in-out rounded-md hover:bg-gray-50"
    >
      <p className="text-base font-medium text-gray-900 transform-gpu skew-x-25">
        {item.title}
      </p>
    </a>
  );
}

function NavDropdownPage({ item }: NavPageProps): JSX.Element {
  return (
    <Link
      key={item.id}
      to={`/${item.page.slug.current}/`}
      className="block p-3 -m-3 transition duration-150 ease-in-out rounded-md hover:bg-gray-50"
    >
      <p className="text-base font-medium text-gray-900 transform-gpu skew-x-25">
        {item.title}
      </p>
    </Link>
  );
}

export { Nav };
