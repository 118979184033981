import * as React from 'react';

import type { ISanityGoogleMap } from '../../fragments/sanity-google-map-parts';
import { useSanityGeneralSettings } from '../../hooks/use-sanity-general-settings';

interface GoogleMapProps {
  googleMap: ISanityGoogleMap;
}

function GoogleMap({ googleMap }: GoogleMapProps): JSX.Element | null {
  return (
    <div className="relative h-96">
      <MapIFrame />
    </div>
  );
}

function MapIFrame(): JSX.Element {
  const { address } = useSanityGeneralSettings();

  return (
    <iframe
      src={address.googleMaps.embed}
      frameBorder={0}
      allowFullScreen
      aria-hidden={false}
      tabIndex={0}
      loading="lazy"
      title="Location"
      className="absolute inset-0 flex-1 w-full h-full"
    />
  );
}

export { GoogleMap, MapIFrame };
