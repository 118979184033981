import { Dialog, Disclosure, Transition } from '@headlessui/react';
import { ChevronLeftIcon, XIcon } from '@heroicons/react/outline';
import { Link } from 'gatsby';
import * as React from 'react';

import { useSanityGeneralSettings } from '../hooks/use-sanity-general-settings';
import type {
  INavDropdown,
  INavLink,
  INavPage,
} from '../hooks/use-sanity-menu';
import { useSanityMenu } from '../hooks/use-sanity-menu';
import { useSanitySEOSettings } from '../hooks/use-sanity-seo-settings';
import { Logo } from '../icons/logo';

interface MobileMenuProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function MobileMenu({ isOpen, setIsOpen }: MobileMenuProps): JSX.Element {
  const { items } = useSanityMenu();
  const { siteTitle } = useSanitySEOSettings();
  const { phoneNumber } = useSanityGeneralSettings();
  return (
    <Transition.Root show={isOpen} as={React.Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-50 overflow-hidden"
        open={isOpen}
        onClose={setIsOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <Transition.Child
              as={React.Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-2xl">
                <div className="flex flex-col h-full overflow-y-scroll bg-primary text-light">
                  <div className="flex items-start justify-between">
                    <Dialog.Title className="sr-only">Mobile Menu</Dialog.Title>
                  </div>
                  <div className="relative flex-1 px-4 mt-3 sm:px-6">
                    {/* Replace with your content */}
                    <div className="overflow-hidden ring-1 ring-primary ring-opacity-5">
                      <div className="flex items-center justify-between px-5 pt-4">
                        <div>
                          <Link
                            to="/"
                            className="flex p-2 -m-2 rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
                          >
                            <span className="sr-only">{siteTitle}</span>
                            <Logo
                              aria-hidden
                              className="w-auto h-8 sm:h-10 text-primary"
                            />
                          </Link>
                        </div>
                        <div className="-mr-2">
                          <button
                            type="button"
                            onClick={() => setIsOpen(false)}
                            className="inline-flex items-center justify-center p-2 transition duration-150 ease-in-out rounded-md bg-light text-primary hover:bg-secondary hover:text-light focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
                          >
                            <span className="sr-only">Close menu</span>
                            <XIcon className="w-6 h-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div className="pt-5 pb-6">
                        <div className="px-2 space-y-1">
                          {items.map((item) => {
                            if (item._type === 'navLink') {
                              return <NavLink key={item.id} item={item} />;
                            }
                            if (item._type === 'navPage') {
                              return <NavPage key={item.id} item={item} />;
                            }
                            if (item._type === 'navDropdown') {
                              return <NavDropdown key={item.id} item={item} />;
                            }
                            return null;
                          })}
                        </div>
                        <div className="px-5 mt-6">
                          <a
                            href={`tel:${phoneNumber}`}
                            className="block w-full px-4 py-3 font-medium text-center transform shadow text-light bg-secondary skew-x-25"
                          >
                            <span className="inline-block transform -skew-x-25">
                              Call: {phoneNumber}
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* /End replace */}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

interface NavLinkProps {
  item: INavLink;
}

function NavLink({ item }: NavLinkProps): JSX.Element {
  return (
    <a
      href={item.url}
      className="block px-3 py-2 text-base font-medium rounded-md hover:bg-light hover:bg-opacity-25"
    >
      {item.title}
    </a>
  );
}

interface NavPageProps {
  item: INavPage;
}

function NavPage({ item }: NavPageProps): JSX.Element {
  const href = item.page.slug ? `/${item.page.slug.current}/` : '/';
  return (
    <Link
      to={href}
      className="block px-3 py-2 text-base font-medium rounded-md hover:bg-light hover:bg-opacity-25 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
    >
      {item.title}
    </Link>
  );
}

interface NavDropdownProps {
  item: INavDropdown;
}

function NavDropdown({ item }: NavDropdownProps): JSX.Element {
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="flex justify-between w-full px-3 py-2 text-base font-medium text-left bg-opacity-0 rounded-md bg-light hover:bg-light hover:bg-opacity-25 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary">
            <span>{item.title}</span>
            <ChevronLeftIcon
              aria-hidden
              className={`${
                open ? 'transform -rotate-90' : ''
              } w-5 h-5 text-light`}
            />
          </Disclosure.Button>
          {/* <Transition
            show={open}
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          > */}
          <Disclosure.Panel
            // static
            className="rounded-md focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
          >
            {item.dropdownItems.map((dropdownItem, index) => {
              if (dropdownItem._type === 'navLink') {
                return <NavLink item={dropdownItem} key={index} />;
              }
              if (dropdownItem._type === 'navPage') {
                return <NavPage item={dropdownItem} key={index} />;
              }
              return null;
            })}
          </Disclosure.Panel>
          {/* </Transition> */}
        </>
      )}
    </Disclosure>
  );
}

export { MobileMenu };
