import { Link } from 'gatsby';
import * as React from 'react';

import type { TCta } from '../fragments/sanity-cta-parts';
import { TColourScheme } from '../types';
import { classNames } from '../utils/classnames';

const BUTTON_LINK_PRIMARY_COLOURS_MAP = {
  transparent: 'text-light bg-secondary border-secondary',
  light: 'text-light bg-primary border-primary',
  primary: 'text-primary bg-light border-light',
  secondary: 'text-light bg-secondary border-secondary',
  dark: 'text-primary bg-light border-light',
};

const BUTTON_LINK_OUTLINE_COLOURS_MAP = {
  light: 'text-primary bg-transparent',
  primary: 'text-light bg-transparent border-light',
  secondary: 'text-primary bg-transparent',
  dark: 'text-light bg-transparent border-light',
};

interface ButtonLinkProps {
  colourScheme?: TColourScheme;
  link: TCta;
}

function ButtonLink({
  link,
  colourScheme = 'secondary',
}: ButtonLinkProps): JSX.Element | null {
  const buttonStyles =
    'inline-flex border-2 uppercase font-semibold tracking-wider transform-gpu skew-x-25';
  const largeStyles = 'px-6 py-2 text-base';
  const smallStyles = 'px-4 py-1 text-sm';
  const styles = classNames(
    link.isButton && buttonStyles,
    link.styles?.style === 'is-primary' &&
      BUTTON_LINK_PRIMARY_COLOURS_MAP[colourScheme],
    link.styles?.style === 'is-outline' &&
      BUTTON_LINK_OUTLINE_COLOURS_MAP[colourScheme],
    link.styles?.isLarge ? largeStyles : smallStyles
  );

  if (link._type === 'pageCta') {
    return (
      <Link to={`/${link.page.slug.current}`} className={styles}>
        <span className="transform-gpu -skew-x-25">{link.text}</span>
      </Link>
    );
  }

  if (link._type === 'linkCta') {
    return (
      <a href={link.url} className={styles}>
        <span className="transform-gpu skew-x-25">{link.text}</span>
      </a>
    );
  }

  if (link._type === 'fileCta') {
    return (
      <a href={link.file.asset.url} className={styles}>
        <span className="transform-gpu skew-x-25">{link.text}</span>
      </a>
    );
  }

  return null;
}

export { ButtonLink };
