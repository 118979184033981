import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';

import type { ISanityHero } from '../../fragments/sanity-hero-parts';
import { ButtonLink } from '../button-link';

interface HeroProps {
  hero: ISanityHero;
}

function Hero({ hero }: HeroProps): JSX.Element {
  return (
    <div className="relative max-h-[40rem] overflow-hidden">
      <div className=" aspect-w-4 aspect-h-3 lg:aspect-w-16 lg:aspect-h-9">
        {hero.backgroundImage?.asset ? (
          <div className="absolute inset-0 flex lg:inset-auto max-h-[40rem]">
            <GatsbyImage
              image={hero.backgroundImage.asset.gatsbyImageData}
              alt={hero.backgroundImage.alt || ''}
              className="flex-1"
            />
          </div>
        ) : null}
        <div className="absolute h-full w-full inset-0 bg-gradient-to-r from-[hsla(0,0%,0%,0.95)]" />
        <div className="relative flex flex-col items-start justify-center py-64 md:py-36 max-h-[40rem] ">
          <div className="px-6 lg:px-8 md:pl-16">
            <h1 className="font-semibold text-light">
              {hero.heading.map((line, index) => (
                <React.Fragment key={line.id}>
                  <span
                    className={`uppercase inline-block leading-tight ${
                      line.isBold ? 'text-3xl md:text-5xl' : 'text-md md:text-3xl'
                    }`}
                  >
                    {line.text}
                  </span>
                  {index !== hero.heading.length - 1 ? <br /> : null}
                </React.Fragment>
              ))}
            </h1>
            <p className="mt-4">
              {hero.ctas
                ? hero.ctas.map((cta) => <ButtonLink key={cta.id} link={cta} />)
                : null}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export { Hero };
