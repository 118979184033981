export const BG_COLOUR_MAP = {
  transparent: 'bg-transparent',
  light: 'bg-light',
  primary: 'bg-gradient-to-b from-secondary to-tertiary',
  secondary: 'bg-secondary',
  dark: 'bg-dark',
};

export const TEXT_COLOUR_MAP = {
  transparent: 'text-light',
  light: 'text-dark',
  primary: 'text-light',
  secondary: 'text-dark',
  dark: 'text-light',
};

export const PROSE_COLOUR_MAP = {
  light: '',
  primary: 'prose-dark',
  secondary: '',
  dark: 'prose-dark',
};

export const ASPECT_RATIO_MAP = {
  none: 'aspect-none',
  square: 'aspect-w-1 aspect-h-1',
  fiveBySeven: 'aspect-w-5 aspect-h-7',
  fourBySix: 'aspect-w-4 aspect-h-6',
  sixteenByNine: 'aspect-w-16 aspect-h-9',
};
